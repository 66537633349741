<template>
  <div>
    <SectionHeading :content="heading.content" :extra_content="heading.extra" />
    <SectionSecondaryHeading
      :content="secondary_heading.content"
      :extra_content="secondary_heading.extra"
    />

    <v-container class="subscriptions">
      <v-row>
        <v-col
          v-bind:key="sub.name"
          v-for="sub in subscriptions"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
        >
          <v-card
            :class="[
              'subcard',
              'mx-auto',
              'my-12',
              sub.recommended ? 'recommended' : '',
            ]"
            max-width="374"
          >
            <v-card-title class="card_heading">
              <div class="logo">
                <v-icon
                  :style="
                    'color: #' +
                    Math.floor(Math.random() * 16777215).toString(16)
                  "
                  >mdi-water-circle</v-icon
                >
              </div>
              <span v-if="sub.recommended" class="recommended"
                >Recommended</span
              >
            </v-card-title>
            <v-card-text class="card_group">
              <span>{{ sub.name }}</span>
              <span>{{ sub.description }}</span>
            </v-card-text>

            <v-card-text class="card_group">
              <span class="sub_price">${{ sub.price }}</span>
              <span>{{ sub.price_billing }}</span>
            </v-card-text>

            <v-card-text class="subscribe_button">
              <v-btn
                @click="order(sub)"
                v-if="sub.id != $user.plan"
                class="ma-2"
                >Purchase</v-btn
              >
              <v-btn v-else :disabled="true" class="ma-2">Current</v-btn>
            </v-card-text>

            <v-card-text class="subscribe_features">
              <div
                v-bind:key="feature"
                v-for="feature in sub.features"
                class="sub-feature"
              >
                <v-icon v-if="feature.slice(0, 2) != 'No'" color="#00FF1A"
                  >mdi-check-bold</v-icon
                >
                <v-icon v-else color="#e74c3c">mdi-close-thick</v-icon>
                <span>{{ feature }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionHeading from "../components/SectionHeading.vue";
import SectionSecondaryHeading from "../components/SectionSecondaryHeading.vue";

export default {
  name: "Subscribe",
  components: {
    SectionHeading,
    SectionSecondaryHeading,
  },

  mounted() {
    const user = this.$user;
    console.log(user);
    if (!user) this.$router.push("/");

    this.heading.extra[0].text = !user.plan
      ? "Terminated/Not Active"
      : user.plan.name + ' until ' + new Date(user.plan.expiry * 1000).toLocaleDateString()
    this.heading.extra[0].color = !user.plan ? "#E74C3C" : "#2ecc71";

    this.plans();
  },

  computed: {},

  methods: {
    
    async order(plan) {
      const data = {
        captcha: "",
        username: this.$user.username,
        email: this.$user.username,
        planId: plan.id,
      };

      try {
        var res = await this.$http.post("/finances/create_charge", data).then(res => res.data)
        
        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content:
            "You will be redirected to the payment page in a few seconds.",
        });

        console.log(res)
        window.location.href = res.payment_url;
      } catch (err) {
         this.$dtoast.pop({
          preset: "error",
          heading: `Error!`,
          content:
            "An unexpected error occured. Please, try again later.",
        });
      }
    },

    async plans() {
      const res = await this.$http
        .post("/public/plans", { captcha: "" })
        .then((res) => res.data);
      this.subscriptions = res.plans
        .map((e) => {
          return {
            ...e,
            recommended: false,
            price_billing:
              "/" + Math.floor(e.duration / 60 / 60 / 24) + " days",
            features: [
              e.raw_query_limit + " search queries",
              e.api_access == 1 ? "API Access" : "No API Access",
            ],
          };
        })
        .sort((a, b) => a.price - b.price);
    },
  },

  data: () => ({
    heading: {
      content: "Subscription",
      extra: [
        { type: "text", text: "Terminated/Not Active", color: "#E74C3C" },
      ],
    },

    secondary_heading: {
      content: "Plans & Pricing",
      extra:
        "Simple Pricing. No Hidden Fees. Advanced Features for your business.",
    },

    subscriptions: [
      {
        recommended: false,
        name: "Weekly",
        logoColor: "#2ECC71",
        description: "Perfect for starters",
        price: "$6.49",
        price_billing: "/weekly",
        features: ["7 Day Access", "Unlimited Access to Search", "Wildcard"],
      },
    ],
  }),
};
</script>

<style lang="scss">
.subscribe_button {
  display: flex;
  justify-content: center;

  button {
    border-radius: 12px !important;
    min-width: 250px !important;
    text-transform: none !important;
    font-size: 13px !important;
    font-family: "Poppins" !important;
    font-weight: 600 !important;
    background: #4d73ff !important;
    color: white !important;
  }
}

.subcard.recommended {
  border: none !important;
  background: #0761f4 !important;
  box-shadow: inherit;

  .recommended {
    color: white !important;
  }
  .card_group {
    span:first-of-type {
      color: white !important;
    }

    span:last-of-type {
      color: #dddddd !important;
    }
  }
  button {
    background: white !important;
    color: black !important;
  }

  .sub-feature {
    span {
      color: white !important;
    }
  }
}

.subscriptions {
  padding: 35px !important;

  .sub-feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    span {
      margin-left: 10px;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 500;
      color: var(--v-secondary-base);
    }

    i {
      font-size: 20px;
    }
  }

  .theme--dark.v-card {
    background: none;
  }

  .subcard {
    min-width: 320px !important;
    padding: 12px;
    background: none;
    border-radius: 15px !important;
    border: 1px solid var(--v-border-base);
    outline: none;
    box-shadow: none !important;

    .card_group {
      display: flex;
      flex-direction: column;
      margin: 10px 0;

      .sub_price {
        font-size: 35px !important;
        font-weight: 800 !important;
        font-family: "Ubuntu" !important;
        margin-bottom: 10px;
      }

      span:first-of-type {
        font-family: "Poppins";
        font-weight: 500;
        font-size: 18px;
        color: var(--v-primary-base);
      }

      span:last-of-type {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 13px;
        color: var(--v-secondary-base);
      }
    }

    .card_heading {
      display: flex;
      flex-direction: row;
      align-items: center;

      .recommended {
        font-size: 12px;
        padding: 0px 6px;
        background: #4d73ff;
        border-radius: 8px;
        font-family: "Ubuntu";
        line-height: 2;
        font-weight: 400;
        user-select: none;
      }

      .logo {
        flex-grow: 1;
        i {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
